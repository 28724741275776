import React, { createContext, useState, useEffect, useContext, useCallback } from 'react';
import { useWallet } from '@solana/wallet-adapter-react';
import { toast } from 'react-toastify';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [authToken, setAuthToken] = useState(null);
  const { publicKey, signMessage, connected } = useWallet();

  const authenticateWallet = useCallback(async () => {
    if (!signMessage) {
      console.error('signMessage function is not available');
      toast.error('Message signing is not supported by your wallet.');
      return;
    }

    try {
      const message = `Sign this message to authenticate with Shirt Haus. This does not trigger a blockchain transaction.`;
      const encodedMessage = new TextEncoder().encode(message);
      // Use wallet adapter's signMessage function
      const signedMessage = await signMessage(encodedMessage);
      // Convert the signed message to a Base64 string
      const signature = Buffer.from(signedMessage).toString('base64');

      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          publicKey: publicKey.toString(),
          signature: signature,
          message: message,
        }),
      });

      if (response.ok) {
        const { token } = await response.json();
        setAuthToken(token);
        localStorage.setItem('authToken', token);
        toast.success('Wallet authenticated successfully');
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Authentication failed');
      }
    } catch (error) {
      console.error('Authentication error:', error);
      toast.error(`Failed to authenticate wallet: ${error.message}`);
    }
  }, [publicKey, signMessage]);

  useEffect(() => {
    if (connected && publicKey) {
      authenticateWallet();
    } else {
      setAuthToken(null);
    }
  }, [publicKey, connected, authenticateWallet]);

  const logout = () => {
    setAuthToken(null);
    localStorage.removeItem('authToken');
  };

  return (
    <AuthContext.Provider value={{ authToken, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
