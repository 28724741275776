import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider } from './components/ThemeContext';
import Minter from './components/Minter';
import LandingPage from './components/LandingPage';
import LaunchCollection from './components/LaunchCollection'; // You'll need to create this component
import CartPage from './components/CartPage';
import { ConnectionProvider } from '@solana/wallet-adapter-react';
import { WalletProvider } from '@solana/wallet-adapter-react';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import { PhantomWalletAdapter } from '@solana/wallet-adapter-wallets';
import { clusterApiUrl } from '@solana/web3.js';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { AuthProvider } from './components/AuthContext';
import TweetsPage from './components/TweetsPage';

const network = WalletAdapterNetwork.Devnet;
const endpoint = clusterApiUrl(network);
const wallets = [new PhantomWalletAdapter()];

function Router() {
  return (
    <ThemeProvider>
      <BrowserRouter>
        <ConnectionProvider endpoint={endpoint}>
          <WalletProvider wallets={wallets} autoConnect>
            <WalletModalProvider>
              <AuthProvider>
                <Routes>
                  <Route path="/" element={<LandingPage />} />
                  <Route path="/mint" element={<Minter />} />
                  <Route path="/launch-collection" element={<LaunchCollection />} />
                  <Route path="/cart" element={<CartPage />} />
                  <Route path="/community" element={<TweetsPage />} />
                  <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
              </AuthProvider>
            </WalletModalProvider>
          </WalletProvider>
        </ConnectionProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default Router;