import React, { useContext, useState } from 'react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { Link } from 'react-router-dom';
import { FaHome, FaSun, FaMoon, FaBars, FaTimes } from 'react-icons/fa';
import { ShoppingCartIcon } from '@heroicons/react/24/solid';
import { ThemeContext } from './ThemeContext';

function Header({ showBackButton, children, cartItemsCount, onCartClick }) {
  const { darkMode, toggleDarkMode } = useContext(ThemeContext);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  return (
    <header className="bg-white dark:bg-gray-800 shadow-md transition-colors duration-500">
      <div className="container mx-auto px-4 py-4">
        <div className="flex justify-between items-center">
          {/* Logo */}
          <Link to="/" className="text-2xl font-bold text-gray-800 dark:text-gray-100 transition-colors duration-300 flex items-center">
            <span className="bg-purple-500 text-white text-xs px-2 py-1 rounded-full mr-2 uppercase tracking-wide">Beta</span>
            shirt.haus
          </Link>

          {/* Mobile menu button */}
          <button
            onClick={toggleMenu}
            className="md:hidden text-gray-800 dark:text-gray-100"
          >
            {isMenuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
          </button>

          {/* Desktop navigation */}
          <nav className="hidden md:flex items-center space-x-4">
            <NavItems darkMode={darkMode} toggleDarkMode={toggleDarkMode} cartItemsCount={cartItemsCount} onCartClick={onCartClick} />
          </nav>
        </div>

        {/* Mobile navigation */}
        {isMenuOpen && (
          <nav className="mt-4 md:hidden">
            <NavItems darkMode={darkMode} toggleDarkMode={toggleDarkMode} cartItemsCount={cartItemsCount} onCartClick={onCartClick} mobile />
          </nav>
        )}
      </div>
    </header>
  );
}

function NavItems({ darkMode, toggleDarkMode, mobile, cartItemsCount, onCartClick }) {
  const itemClass = mobile
    ? "block w-full text-center mb-3 text-gray-800 dark:text-gray-100"
    : "text-gray-800 dark:text-gray-100";

  const buttonClass = mobile
    ? "w-full px-6 py-3 rounded-lg shadow-lg transition-colors duration-300"
    : "px-6 py-2 rounded-lg shadow-lg transition-colors duration-300";

  return (
    <div className={mobile ? "flex flex-col space-y-3" : "flex items-center space-x-4"}>
      <Link to="/" className={`${itemClass} text-2xl font-bold flex items-center justify-center ${mobile ? buttonClass : ''}`}>
        <FaHome className="mr-2" />
        {mobile && "Home"}
      </Link>

      <button
        onClick={toggleDarkMode}
        className={`${itemClass} ${buttonClass} flex items-center justify-center bg-gray-200 dark:bg-gray-700 hover:bg-gray-300 dark:hover:bg-gray-600`}
      >
        {darkMode ? <FaSun className="mr-2" /> : <FaMoon className="mr-2" />}
        {darkMode ? 'Light Mode' : 'Dark Mode'}
      </button>

      <Link
        to="/mint"
        className={`${itemClass} ${buttonClass} bg-purple-600 dark:bg-purple-700 text-white hover:bg-purple-700 dark:hover:bg-purple-800`}
      >
        Mint
      </Link>

      <Link
        to="/launch-collection"
        className={`${itemClass} ${buttonClass} bg-green-500 dark:bg-green-600 text-white hover:bg-green-600 dark:hover:bg-green-700`}
      >
        Launch Your Physical NFTs
      </Link>

      <button
        onClick={onCartClick}
        className={`${itemClass} ${buttonClass} flex items-center justify-center bg-gray-200 dark:bg-gray-700 hover:bg-gray-300 dark:hover:bg-gray-600`}
      >
        <ShoppingCartIcon className="h-6 w-6 mr-2" />
        <span>{cartItemsCount}</span>
      </button>

      <WalletMultiButton className={`${itemClass} ${buttonClass} bg-purple-600 dark:bg-purple-700 text-white hover:bg-purple-700 dark:hover:bg-purple-800`} />
    </div>
  );
}

export default Header;
