import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useWallet } from '@solana/wallet-adapter-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ThemeContext } from './ThemeContext';
import NFTGallery from './NFTGallery';
import Header from './Header';
import Cart from './Cart';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { removeBackgroundAdobe } from '../utils/adobePhotoshop';
import { useAuth } from './AuthContext';
import { getProductImageUrl } from '../utils/imageUtils';

function Minter() {
  const { publicKey } = useWallet();
  const { authToken } = useAuth();
  const { darkMode } = useContext(ThemeContext);
  const [selectedNFT, setSelectedNFT] = useState(null);
  const [ownedNFTs, setOwnedNFTs] = useState([]);
  const [selectedPosition, setSelectedPosition] = useState('front');
  const [frontNFT, setFrontNFT] = useState(null);
  const [backNFT, setBackNFT] = useState(null);
  const [popularNFTs, setPopularNFTs] = useState([]);
  const [selectedSize, setSelectedSize] = useState('M');
  const [selectedColor, setSelectedColor] = useState('black');
  const [cartItems, setCartItems] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [showCart, setShowCart] = useState(false);
  const [isProcessingImage, setIsProcessingImage] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [selectedCollection, setSelectedCollection] = useState('all');
  const [allowlist, setAllowlist] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedFrame, setSelectedFrame] = useState(null);

  const fetchAllowlist = useCallback(async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/allowlist`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setAllowlist(data);
    } catch (error) {
      console.error('Error fetching allowlist:', error);
      setAllowlist([]);
    }
  }, []);

  useEffect(() => {
    fetchAllowlist();
  }, [fetchAllowlist]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const collectionId = params.get('collectionId') || params.get('collection');
    if (collectionId) {
      setSelectedCollection(collectionId);
    }
  }, [location]);

  const fetchOwnedNFTs = useCallback(async () => {
    if (publicKey && authToken) {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/wallets/${publicKey.toString()}/nfts?forceUpdate=${forceUpdate}&collection=${selectedCollection}`, {
          headers: {
            'Authorization': `Bearer ${authToken}`,
          },
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        console.log(data);
        setOwnedNFTs(data.nftIds || []);
        if (forceUpdate) {
          setForceUpdate(false);
        }
      } catch (error) {
        console.error('Error fetching owned NFTs:', error);
        toast.error('Failed to fetch owned NFTs');
      }
    }
  }, [publicKey, authToken, forceUpdate, selectedCollection]);

  useEffect(() => {
    fetchOwnedNFTs();
  }, [fetchOwnedNFTs]);

  // Fetch popular NFTs
  useEffect(() => {
    // Placeholder data; replace with actual API call if available
    setPopularNFTs([
      { id: 1, tokenImage: "https://arweave.net/d_2v2Vh9fHGCj8NkZTZE1ZiHc7YqC2gO5Jbjr-FJFl4?ext=jpg" },
      { id: 2, tokenImage: "https://arweave.net/KK_Fu3oRPTo4EOZYbXdSfA4r4nXQxLVK2XXRm_aOvtA?ext=gif" },
      { id: 3, tokenImage: "https://arweave.net/gQ1KtfbKLRVv47ncFfXVHaLeEfCaVxSfHFbfgQznnk8?ext=jpg" },
      { id: 4, tokenImage: "https://arweave.net/MgoPVd31F59RVZSIdRvaEI2ulK-gx5HFsszhi3Eqk5w?ext=jpg" },
    ]);
  }, []);

  const fetchCartItems = useCallback(async () => {
    if (!publicKey) return;
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/cart/${publicKey.toString()}`, {
        headers: {
          'Authorization': `Bearer ${authToken}`,
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const cart = await response.json();
      setCartItems(cart.items);
    } catch (error) {
      console.error('Error fetching cart items:', error);
      setCartItems([]);
    }
  }, [publicKey, authToken]);

  useEffect(() => {
    if (publicKey) {
      fetchCartItems();
    }
  }, [publicKey, fetchCartItems]);

  const addToCart = async (newItem) => {
    if (!publicKey) {
      toast.error('Wallet not connected.', {
        position: "bottom-right",
        autoClose: 3000,
      });
      return;
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/cart/add`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`,
        },
        body: JSON.stringify({ walletAddress: publicKey.toString(), item: newItem }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const updatedCart = await response.json();
      setCartItems(updatedCart.items);
      toast.success('Item added to Mint Cart!', {
        position: "bottom-right",
        autoClose: 3000,
      });
      setQuantity(1);
    } catch (error) {
      console.error('Error adding item to Mint Cart:', error);
      toast.error('Failed to add item to Mint Cart. Please try again.', {
        position: "bottom-right",
        autoClose: 3000,
      });
    }
  };

  const handleAddToMintCart = async () => {
    if (!selectedProduct) {
      toast.error('Please select a product');
      return;
    }
    console.log(`Selected product: ${JSON.stringify(selectedProduct)}`);

    const newItem = {
      name: selectedProduct.name,
      productId: selectedProduct._id,
      productType: selectedProduct.type,
      size: selectedSize,
      color: selectedColor,
      images: {
        front: frontNFT ? frontNFT.tokenImage : null,
        back: backNFT ? backNFT.tokenImage : null,
      },
      quantity: quantity,
      price: 34.99, // todo map price from backend // todo: get the price from the nft, or image the artist set
      tokenId: frontNFT ? frontNFT.tokenId : (backNFT ? backNFT.tokenId : null)
    };
    
    await addToCart(newItem);
  };

  const removeFromCart = async (itemIndex) => {
    if (!publicKey) return;
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/cart/remove`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`,
        },
        body: JSON.stringify({ walletAddress: publicKey.toString(), index: itemIndex }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const updatedCart = await response.json();
      setCartItems(updatedCart.items);
      toast.info('Item removed from cart', {
        position: "bottom-right",
        autoClose: 3000,
      });
    } catch (error) {
      console.error('Error removing item from cart:', error);
      toast.error('Failed to remove item from cart. Please try again.', {
        position: "bottom-right",
        autoClose: 3000,
      });
    }
  };

  const toggleCart = () => {
    setShowCart(!showCart);
  };

  const handleSelectNFT = (nft) => {
    if (selectedPosition === 'front') {
      setFrontNFT(nft);
    } else {
      setBackNFT(nft);
    }
  };

  const handleRemoveBackground = async () => {
    const nft = selectedPosition === 'front' ? frontNFT : backNFT;
    if (!nft) {
      toast.error('No NFT selected for background removal.', { position: "bottom-right" });
      return;
    }

    setIsProcessingImage(true);
    const loadingToastId = toast.loading('Removing background... This may take a while.', {
      position: "bottom-right",
    });

    try {
      const processedImageUrl = await removeBackgroundAdobe(nft.tokenImage);

      if (selectedPosition === 'front') {
        setFrontNFT({ ...nft, tokenImage: processedImageUrl });
      } else {
        setBackNFT({ ...nft, tokenImage: processedImageUrl });
      }

      toast.update(loadingToastId, {
        render: 'Image processed successfully!',
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });
    } catch (error) {
      console.error('Error processing image:', error);
      toast.update(loadingToastId, {
        render: 'Failed to process image. Please try again.',
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });
    } finally {
      setIsProcessingImage(false);
    }
  };

  const handleRefreshNFTs = () => {
    setForceUpdate(true);
  };

  const handleCollectionChange = (e) => {
    const newCollectionId = e.target.value;
    setSelectedCollection(newCollectionId);
    // Update URL with new collection
    navigate(`?collection=${newCollectionId}`, { replace: true });
  };

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/products`);
        if (!response.ok) throw new Error('Failed to fetch products');
        const data = await response.json();
        setProducts(data);
        if (data.length > 0) {
          setSelectedProduct(data[0]);
          setSelectedSize(data[0].sizes[0] || 'M');
          setSelectedColor(data[0].colors[0]?.name || 'black');
        }
      } catch (error) {
        console.error('Error fetching products:', error);
        toast.error('Failed to load products');
      }
    };

    fetchProducts();
  }, []);

  const handleProductChange = async (productId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/products/${productId}`);
      if (!response.ok) throw new Error('Failed to fetch product details');
      const product = await response.json();
      setSelectedProduct(product);
      setSelectedSize(product.sizes[0] || 'M');
      setSelectedColor(product.colors[0]?.name || 'black');
    } catch (error) {
      console.error('Error fetching product details:', error);
      toast.error('Failed to load product details');
    }
  };

  const getTemplateImage = () => {
    if (!selectedProduct) return null;
    const imageUrl = getProductImageUrl(
      selectedProduct._id,
      selectedProduct.name.toLowerCase().replace(/\s+/g, '-'),
      {
        position: selectedPosition,
        color: selectedColor,
        frame: selectedFrame // if it's a framed product
      }
    );
    return imageUrl;
  };

  return (
    <div className={`min-h-screen bg-gray-100 dark:bg-gray-900 flex flex-col transition-colors duration-500 ${darkMode ? 'dark' : ''}`}>
      {/* Header with Dark Mode Toggle and Cart Button */}
      <Header 
        showBackButton={true} 
        cartItemsCount={cartItems.length}
        onCartClick={toggleCart}
      />

      <div className="container mx-auto p-6 flex-grow">
        {showCart ? (
          <Cart
            cartItems={cartItems}
            removeFromCart={removeFromCart}
            onClose={toggleCart}
            fetchCartItems={fetchCartItems}
          />
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {/* Shirt Preview */}
            <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg transition-colors duration-500">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-bold text-gray-800 dark:text-gray-100">Shirt Preview</h2>
                <div>
                  <button
                    className={`px-4 py-2 rounded-l-lg ${selectedPosition === 'front' ? 'bg-purple-500 text-white' : 'bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200'}`}
                    onClick={() => setSelectedPosition('front')}
                  >
                    Front
                  </button>
                  <button
                    className={`px-4 py-2 rounded-r-lg ${selectedPosition === 'back' ? 'bg-purple-500 text-white' : 'bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200'}`}
                    onClick={() => setSelectedPosition('back')}
                  >
                    Back
                  </button>
                </div>
              </div>
              <div className="relative">
                <img
                  src={getTemplateImage()}
                  alt={`${selectedProduct?.name} ${selectedColor} ${selectedPosition}`}
                  className="w-full h-auto"
                />
                {selectedPosition === 'front' && frontNFT && (
                  <img
                    src={frontNFT.tokenImage}  // This should now be the processed image
                    alt="Front NFT"
                    className="absolute top-1/4 left-1/4 w-1/2 h-[37.5%] object-contain"
                  />
                )}
                {selectedPosition === 'back' && backNFT && (
                  <img
                    src={backNFT.tokenImage}  // This should now be the processed image
                    alt="Back NFT"
                    className="absolute top-1/4 left-1/4 w-1/2 h-[37.5%] object-contain"
                  />
                )}
              </div>
              <div className="mt-4">
                <h3 className="text-lg font-semibold mb-2 text-gray-800 dark:text-gray-100">Popular NFTs</h3>
                <div className="grid grid-cols-4 gap-2">
                  {popularNFTs.map((nft) => (
                    <img
                      key={nft.id}
                      src={nft.tokenImage}
                      alt={`Popular NFT ${nft.id}`}
                      className="w-full h-auto object-cover rounded cursor-pointer hover:opacity-75 transition-opacity"
                      onClick={() => handleSelectNFT(nft)}
                    />
                  ))}
                </div>
              </div>
              <button
                className="mt-4 bg-purple-500 text-white px-4 py-2 rounded-lg hover:bg-purple-600 transition"
                onClick={handleRemoveBackground}
                disabled={isProcessingImage}
              >
                {isProcessingImage ? 'Removing Background...' : 'Remove Background'}
              </button>
            </div>

            {/* My NFTs */}
            <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg transition-colors duration-500">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-bold text-gray-800 dark:text-gray-100">
                  My NFTs
                  <span className="ml-2 relative group">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-500 dark:text-gray-300" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M18 10A8 8 0 112 10a8 8 0 0116 0zm-8-4a1 1 0 100 2 1 1 0 000-2zm1 4a1 1 0 00-2 0v3a1 1 0 002 0v-3z" clipRule="evenodd" />
                    </svg>
                    <div className="absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 px-2 py-1 bg-gray-700 text-white text-xs rounded opacity-0 group-hover:opacity-100 transition-opacity w-48 text-center">
                      Only NFTs approved by artists and owners are shown
                    </div>
                  </span>
                </h2>
                <div className="flex items-center">
                  <select
                    value={selectedCollection}
                    onChange={handleCollectionChange}
                    className="mr-2 border rounded px-2 py-1 dark:bg-gray-700 dark:text-gray-100"
                  >
                    <option value="all">All Collections</option>
                    {allowlist.map((item) => (
                      <option key={item._id} value={item._id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                  <button
                    onClick={handleRefreshNFTs}
                    disabled={forceUpdate}
                    className="bg-purple-500 text-white px-4 py-2 rounded-lg hover:bg-purple-600 transition disabled:opacity-50"
                  >
                    {forceUpdate ? 'Refreshing...' : 'Refresh NFTs'}
                  </button>
                </div>
              </div>
              <NFTGallery nfts={ownedNFTs} onSelectNFT={handleSelectNFT} />
              <div className="mt-4 text-gray-800 dark:text-gray-100">
                <div className="mb-4">
                  <label htmlFor="product-select" className="mr-2">Product:</label>
                  <select
                    id="product-select"
                    value={selectedProduct?._id}
                    onChange={(e) => handleProductChange(e.target.value)}
                    className="border rounded px-2 py-1 dark:bg-gray-700 dark:text-gray-100 mr-4"
                  >
                    {products.map((product) => (
                      <option key={product._id} value={product._id}>
                        {product.name}
                      </option>
                    ))}
                  </select>
                </div>
                
                <div className="mb-2">
                  <label htmlFor="size-select" className="mr-2">Size:</label>
                  <select
                    id="size-select"
                    value={selectedSize}
                    onChange={(e) => setSelectedSize(e.target.value)}
                    className="border rounded px-2 py-1 dark:bg-gray-700 dark:text-gray-100 mr-4"
                  >
                    {selectedProduct?.sizes.map((size) => (
                      <option key={size} value={size}>{size}</option>
                    ))}
                  </select>
                  
                  <label htmlFor="color-select" className="mr-2">Color:</label>
                  <select
                    id="color-select"
                    value={selectedColor}
                    onChange={(e) => setSelectedColor(e.target.value)}
                    className="border rounded px-2 py-1 dark:bg-gray-700 dark:text-gray-100"
                  >
                    {selectedProduct?.colors.map((color) => (
                      <option key={color.name} value={color.name}>{color.name}</option>
                    ))}
                  </select>
                  <label htmlFor="quantity-select" className="mr-2 text-gray-800 dark:text-gray-100">Quantity:</label>
                  <input
                    id="quantity-select"
                    type="number"
                    min="1"
                    value={quantity}
                    onChange={(e) => setQuantity(Math.max(1, parseInt(e.target.value)))}
                    className="border rounded px-2 py-1 dark:bg-gray-700 dark:text-gray-100 w-16"
                  />
                </div>
              </div>
              <button
                className="bg-purple-200 text-purple-800 px-4 py-2 rounded-lg mt-4 dark:bg-purple-700 dark:text-white hover:bg-purple-300 dark:hover:bg-purple-600 transition"
                onClick={handleAddToMintCart}
              >
                Add to Mint Cart
              </button>
            </div>
          </div>
        )}
      </div>

      {/* You can add a footer here if needed */}
      
      {/* Add ToastContainer at the end of the component */}
      <ToastContainer />
    </div>
  );
}

export default Minter;
