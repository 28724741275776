import React from 'react';
import Cart from './Cart';
import Header from './Header';
import { useWallet } from '@solana/wallet-adapter-react';

function CartPage() {
  const { publicKey } = useWallet();

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900 flex flex-col transition-colors duration-500">
      <Header showBackButton={true} />
      <div className="container mx-auto p-6 flex-grow">
        <Cart publicKey={publicKey} />
      </div>
    </div>
  );
}

export default CartPage;