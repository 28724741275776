import React, { useEffect, useContext } from 'react';
import Header from './Header';
import { ThemeContext } from './ThemeContext';
import TwitterEmbed from './TwitterEmbed';
import twitterPosts from './data/twitterPosts';

const TweetsPage = () => {
  const { darkMode } = useContext(ThemeContext);

  useEffect(() => {
    // Load Twitter widgets script if not already loaded
    if (!document.querySelector('script[src="https://platform.twitter.com/widgets.js"]')) {
      const script = document.createElement('script');
      script.src = 'https://platform.twitter.com/widgets.js';
      script.async = true;
      document.body.appendChild(script);
    }
  }, []);

  return (
    <div className={`min-h-screen bg-gray-100 dark:bg-gray-900 flex flex-col transition-colors duration-500 ${darkMode ? 'dark' : ''}`}>
      <Header />
      <main className="flex-grow container mx-auto p-6">
        <h1 className="text-6xl font-extrabold my-16 mx-4 text-gray-800 dark:text-gray-100 text-center">
          {/* <span className="bg-clip-text text-transparent bg-gradient-to-r from-purple-500 to-green-700"> */}
          <span className="">
            Shirt Haus Community on X
          </span>
        </h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {twitterPosts.map((post, index) => (
            <TwitterEmbed key={index} html={post} darkMode={darkMode} />
          ))}
        </div>
      </main>
      <footer className="bg-white dark:bg-gray-800 shadow">
        <div className="container mx-auto p-6 text-center text-gray-600 dark:text-gray-300">
          &copy; {new Date().getFullYear()} shirt.haus. All rights reserved.
        </div>
      </footer>
    </div>
  );
};

export default TweetsPage;
