import React, { useState, useMemo, useContext } from 'react';
import { ThemeContext } from './ThemeContext';

const NFTGallery = ({ nfts, onSelectNFT }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedCollection, setSelectedCollection] = useState('All');
  const { darkMode } = useContext(ThemeContext);
  const nftsPerPage = 16;

  const collections = useMemo(() => {
    const collectionSet = new Set(nfts.map(nft => nft.collectionName));
    return ['All', ...Array.from(collectionSet)];
  }, [nfts]);

  const filteredNFTs = useMemo(() => {
    return selectedCollection === 'All'
      ? nfts
      : nfts.filter(nft => nft.collectionName === selectedCollection);
  }, [nfts, selectedCollection]);

  const displayedNFTs = filteredNFTs.slice(currentPage * nftsPerPage, (currentPage + 1) * nftsPerPage);

  const totalPages = Math.ceil(filteredNFTs.length / nftsPerPage);

  return (
    <div className={`${darkMode ? 'dark' : ''}`}>
      

      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-2">
        {displayedNFTs.map((nft) => (
          <div
            key={nft.tokenId}
            className="cursor-pointer hover:opacity-75 transition-opacity dark:border-gray-700 dark:bg-gray-800 rounded"
            onClick={() => onSelectNFT(nft)}
          >
            <img
              src={nft.tokenImage}
              alt={nft.tokenName}
              className="w-full h-auto object-cover aspect-square rounded"
            />
          </div>
        ))}
      </div>
      {totalPages > 1 && (
        <div className="mt-4 flex justify-center">
          <button
            onClick={() => setCurrentPage((prev) => Math.max(0, prev - 1))}
            disabled={currentPage === 0}
            className="bg-purple-200 text-purple-800 px-2 py-1 rounded mr-2 dark:bg-purple-700 dark:text-white disabled:opacity-50"
          >
            Previous
          </button>
          <span className="mx-2 text-gray-800 dark:text-gray-100">
            Page 
            <input
              type="number"
              min="1"
              max={totalPages}
              value={currentPage + 1}
              onChange={(e) => {
                const page = Math.max(1, Math.min(totalPages, parseInt(e.target.value))) - 1;
                setCurrentPage(page);
              }}
              className="w-12 text-center border rounded mx-1 dark:bg-gray-700 dark:text-gray-100"
            />
            of {totalPages}
          </span>
          <button
            onClick={() => setCurrentPage((prev) => Math.min(totalPages - 1, prev + 1))}
            disabled={currentPage === totalPages - 1}
            className="bg-purple-200 text-purple-800 px-2 py-1 rounded ml-2 dark:bg-purple-700 dark:text-white disabled:opacity-50"
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
};

export default NFTGallery;