export const getProductImageUrl = (productId, productName, options = {}) => {
  const baseUrl = process.env.REACT_APP_R2_PUBLIC_URL;
  const { position = 'front', color, frame } = options;
  // make color, frame and position lowercase and replace spaces with dashes
  const colorLower = color?.toLowerCase().replace(/\s+/g, '-');
  const frameLower = frame?.toLowerCase().replace(/\s+/g, '-');
  const positionLower = position?.toLowerCase().replace(/\s+/g, '-');
  // For apparel
  if (colorLower) {
    return `${baseUrl}/${productId}-${productName}-${colorLower}-${positionLower}.png`;
  }
  
  // For prints/frames
  if (frameLower) {
    return `${baseUrl}/${productId}-${productName}-${frameLower}-single.png`;
  }
  
  // Default single image products
  return `${baseUrl}/${productId}-${productName}-single.png`;
}; 