// frontend/src/utils/adobePhotoshop.js

export const removeBackgroundAdobe = async (imageUrl) => {
  const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/remove-background`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ imageUrl }),
  });

  if (!response.ok) {
    throw new Error('Failed to remove background');
  }

  const data = await response.json();

  if (data && data.outputUrl) {
    return data.outputUrl;
  } else {
    throw new Error('Failed to remove background');
  }
};