import React, { useState, useContext } from 'react';
import { ThemeContext } from './ThemeContext';

const faqs = [
  {
    question: 'How do I launch my physical NFTs?',
    answer: 'Artists and owners can launch their physical NFTs by visiting the Launch Your Physical NFTs page with the push of a button.',
  },
  {
    question: 'Are the NFT images approved?',
    answer: 'Yes, all images are approved by the artists and owners to ensure authenticity.',
  },
  // {
  //   question: 'How many shirts are available?',
  //   answer: 'We are launching a limited edition of 1000 NFT shirts.',
  // },
  {
    question: 'What is the cost of minting a shirt?',
    answer: 'Each shirt costs 0.35 SOL.',
  }
  // Add more FAQs as needed
];

const FAQAccordion = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const { darkMode } = useContext(ThemeContext);

  const toggleAccordion = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  return (
    <div className={`space-y-4 ${darkMode ? 'dark' : ''}`}>
      {faqs.map((faq, index) => (
        <div key={index} className="border rounded-lg dark:border-gray-700">
          <button
            onClick={() => toggleAccordion(index)}
            className="rounded-lg w-full px-4 py-2 text-left flex justify-between items-center focus:outline-none dark:bg-gray-800"
          >
            <span className="text-xl font-medium text-gray-800 dark:text-gray-100">{faq.question}</span>
            <svg
              className={`w-6 h-6 transform transition-transform ${
                activeIndex === index ? 'rotate-180' : 'rotate-0'
              } text-gray-800 dark:text-gray-100`}
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
            </svg>
          </button>
          {activeIndex === index && (
            <div className="px-4 py-2 bg-gray-50 dark:bg-gray-700 rounded-lg">
              <p className="text-gray-700 dark:text-gray-300">{faq.answer}</p>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default FAQAccordion;