import React, { useEffect } from 'react';

const TwitterEmbed = ({ html, darkMode }) => {
  useEffect(() => {
    // Reload Twitter widgets whenever a new tweet is added or darkMode changes
    if (window.twttr && window.twttr.widgets) {
      window.twttr.widgets.load();
    }
  }, [html, darkMode]);

  // Function to add data-theme attribute to blockquote
  const addThemeToHtml = (htmlString, theme) => {
    const themeAttribute = `data-theme="${theme}"`;
    // Insert the data-theme attribute after class="twitter-tweet"
    return htmlString.replace(
      /<blockquote class="twitter-tweet"/,
      `<blockquote class="twitter-tweet" ${themeAttribute}`
    );
  };

  const modifiedHtml = addThemeToHtml(html, darkMode ? 'dark' : 'light');

  return (
    <div
      className="w-full"
      dangerouslySetInnerHTML={{ __html: modifiedHtml }}
    />
  );
};

export default TwitterEmbed;
