const twitterPosts = [
    `<blockquote class="twitter-tweet">
      <p lang="en" dir="ltr">
        😂😂😂 <a href="https://twitter.com/shirt_haus?ref_src=twsrc%5Etfw">@shirt_haus</a> just makes them look bigger 👀 it’s all an illusion 🥸 
        <a href="https://t.co/K4411bLGE0">pic.twitter.com/K4411bLGE0</a>
      </p>
      &mdash; BIG_ ₳L_ST₳SH_420_69🥸🛡️ (@Big_Al_225) 
      <a href="https://twitter.com/Big_Al_225/status/1823344098788426008?ref_src=twsrc%5Etfw">August 13, 2024</a>
    </blockquote>`,
    // `<blockquote class="twitter-tweet">
    //   <p lang="en" dir="ltr">
    //     Our first debate is underway at Left Curve! <br><br>
    //     Is crypto&#39;s only use case speculation?
    //     <a href="https://twitter.com/vibhu?ref_src=twsrc%5Etfw">@vibhu</a> vs 
    //     <a href="https://twitter.com/tarunchitra?ref_src=twsrc%5Etfw">@tarunchitra</a> 
    //     <a href="https://t.co/wrlr9IC8dz">pic.twitter.com/wrlr9IC8dz</a>
    //   </p>
    //   &mdash; Solana Breakpoint ☀️ ABU DHABI Dec. 11-13 (@SolanaConf) 
    //   <a href="https://twitter.com/SolanaConf/status/1836975109023932519?ref_src=twsrc%5Etfw">September 20, 2024</a>
    // </blockquote>`,
  `<blockquote class="twitter-tweet">
    <p lang="en" dir="ltr">
      My <a href="https://twitter.com/solanapoet?ref_src=twsrc%5Etfw">@solanapoet</a> 👀 wif <a href="https://twitter.com/search?q=%24STASH&amp;src=ctag&amp;ref_src=twsrc%5Etfw">$STASH</a> sweatshirt arrived today! It's Fkn awesome! The print and the material are amazing 🔥🔥 <a href="https://twitter.com/shirt_haus?ref_src=twsrc%5Etfw">@shirt_haus</a> If you like high quality products def recommend, it's not cheap shit. <a href="https://t.co/RjX115QFSJ">pic.twitter.com/RjX115QFSJ</a>
    </p>
    &mdash; D₳NNY D. // (@DeojayDan) 
    <a href="https://twitter.com/DeojayDan/status/1794109830405247222?ref_src=twsrc%5Etfw">May 24, 2024</a>
  </blockquote>`,
    // `<blockquote class="twitter-tweet">
    //   <p lang="en" dir="ltr">
    //     Solana is technology built to end speculation, not promote it. 
    //     <a href="https://t.co/C3qIS2jchl">pic.twitter.com/C3qIS2jchl</a>
    //   </p>
    //   &mdash; vibhu (@vibhu) 
    //   <a href="https://twitter.com/vibhu/status/1838418809947902029?ref_src=twsrc%5Etfw">September 24, 2024</a>
    // </blockquote>`,
    `<blockquote class="twitter-tweet">
      <p lang="en" dir="ltr">
        Even <a href="https://twitter.com/vibhu?ref_src=twsrc%5Etfw">@vibhu</a> wears artist appeal provided by <a href="https://twitter.com/shirt_haus?ref_src=twsrc%5Etfw">@shirt_haus</a> - what a way to show tremendous display of loyalty to creators!!! <a href="https://t.co/GAAu7f9qiA">https://t.co/GAAu7f9qiA</a>
      </p>
      &mdash; BIG_ ₳L_ST₳SH_420_69🥸🛡️ (@Big_Al_225) 
      <a href="https://twitter.com/Big_Al_225/status/1837436760592101869?ref_src=twsrc%5Etfw">September 21, 2024</a>
    </blockquote>`,
    `<blockquote class="twitter-tweet">
    <p lang="en" dir="ltr">
      I&#39;ve gotta say it,after wear this awesome shirt from <a href="https://twitter.com/shirt_haus?ref_src=twsrc%5Etfw">@shirt_haus</a> now i can lift more weight..<br><br>Thank you for the high quality shirt!! <a href="https://t.co/0fg6bxbNQV">pic.twitter.com/0fg6bxbNQV</a>
    </p>
    &mdash; AmeT (@amir_hamz_) 
    <a href="https://twitter.com/amir_hamz_/status/1833208748774772909?ref_src=twsrc%5Etfw">September 9, 2024</a>
  </blockquote>`,

    `<blockquote class="twitter-tweet">
      <p lang="qst" dir="ltr">
        art <a href="https://t.co/OthA3Cj6GD">https://t.co/OthA3Cj6GD</a>
      </p>
      &mdash; degen poet (@solanapoet) 
      <a href="https://twitter.com/solanapoet/status/1797022463789814115?ref_src=twsrc%5Etfw">June 1, 2024</a>
    </blockquote>`,
    `<blockquote class="twitter-tweet">
      <p lang="en" dir="ltr">
        Now you need the legendary <a href="https://twitter.com/vibhu?ref_src=twsrc%5Etfw">@vibhu</a> shirt from <a href="https://twitter.com/shirt_haus?ref_src=twsrc%5Etfw">@shirt_haus</a> 🫡 
        <a href="https://t.co/sdXmL5ZUPm">pic.twitter.com/sdXmL5ZUPm</a>
      </p>
      &mdash; BIG_ ₳L_ST₳SH_420_69🥸🛡️ (@Big_Al_225) 
      <a href="https://twitter.com/Big_Al_225/status/1832463597080899700?ref_src=twsrc%5Etfw">September 7, 2024</a>
    </blockquote>`,
    // `<blockquote class="twitter-tweet">
    //   <p lang="en" dir="ltr">
    //     <a href="https://twitter.com/search?q=%24STASH&amp;src=ctag&amp;ref_src=twsrc%5Etfw">$STASH</a> stays twirled. Trying to get yoked like 
    //     <a href="https://twitter.com/JFlashGordonMMA?ref_src=twsrc%5Etfw">@JFlashGordonMMA</a> 
    //     <a href="https://twitter.com/johnkim77?ref_src=twsrc%5Etfw">@johnkim77</a> <br><br>
    //     2XL from <a href="https://twitter.com/solanapoet?ref_src=twsrc%5Etfw">@solanapoet</a> 
    //     <a href="https://twitter.com/shirt_haus?ref_src=twsrc%5Etfw">@shirt_haus</a> 
    //     <a href="https://twitter.com/drip_haus?ref_src=twsrc%5Etfw">@drip_haus</a> 
    //     <a href="https://twitter.com/vibhu?ref_src=twsrc%5Etfw">@vibhu</a> is 🔥🔥🔥fits very well.<br><br>
    //     100% Organic cotton, 100% organic community growth, 100% organic twirl.
    //     <a href="https://twitter.com/blknoiz06?ref_src=twsrc%5Etfw">@blknoiz06</a> 
    //     <a href="https://twitter.com/SolJakey?ref_src=twsrc%5Etfw">@SolJakey</a> 
    //     <a href="https://twitter.com/gabrielhaines?ref_src=twsrc%5Etfw">@gabrielhaines</a> <br>
    //     @SkoobTheLife… 
    //     <a href="https://t.co/yhh2EyLDWJ">https://t.co/yhh2EyLDWJ</a> 
    //     <a href="https://t.co/NLBT8ai4mr">pic.twitter.com/NLBT8ai4mr</a>
    //   </p>
    //   &mdash; BIG_ ₳L_ST₳SH_420_69🥸🛡️ (@Big_Al_225) 
    //   <a href="https://twitter.com/Big_Al_225/status/1797965136994410853?ref_src=twsrc%5Etfw">June 4, 2024</a>
    // </blockquote>`,
  `<blockquote class="twitter-tweet">
    <p lang="en" dir="ltr">
      <a href="https://twitter.com/solanapoet?ref_src=twsrc%5Etfw">@solanapoet</a> art:<br>2024: shirts, playing cards, living rooms, &amp; books <br><br>2034: museums, galleries, &amp; encyclopedias<br><br>(rocking this shirt from <a href="https://twitter.com/shirt_haus?ref_src=twsrc%5Etfw">@shirt_haus</a> by my man <a href="https://twitter.com/simerdinger_sol?ref_src=twsrc%5Etfw">@simerdinger_sol</a> while buying some packs to add to my collection🃏🎴) <a href="https://t.co/UudwQucnaD">pic.twitter.com/UudwQucnaD</a>
    </p>
    &mdash; Marvs Decks 👑💥 (@kuyamarvs_sol) 
    <a href="https://twitter.com/kuyamarvs_sol/status/1798322692149670258?ref_src=twsrc%5Etfw">June 5, 2024</a>
  </blockquote>`,
  `<blockquote class="twitter-tweet">
    <p lang="en" dir="ltr">
      Order came from <a href="https://twitter.com/shirt_haus?ref_src=twsrc%5Etfw">@shirt_haus</a> <br><br>Wife loves it, and immediately put it on! <a href="https://t.co/kdWjd6bQpm">pic.twitter.com/kdWjd6bQpm</a>
    </p>
    &mdash; Player 42 (@stash_stays) 
    <a href="https://twitter.com/stash_stays/status/1789384595173191839?ref_src=twsrc%5Etfw">May 11, 2024</a>
  </blockquote>`,
  `<blockquote class="twitter-tweet">
    <p lang="en" dir="ltr">
      Burn baby <a href="https://t.co/1vexTmLxQ5">pic.twitter.com/1vexTmLxQ5</a>
    </p>
    &mdash; vibhu (@vibhu) 
    <a href="https://twitter.com/vibhu/status/1805641007544897986?ref_src=twsrc%5Etfw">June 25, 2024</a>
  </blockquote>`,
  ];
  
  export default twitterPosts;
